import React from 'react';
import { Container } from '../../components';
import BlogItem from '../../components/Blog/BlogItem';
import petSaveta from '../../assets/blog/article1/sarene-zavese.webp';
import bojaZavesa from '../../assets/blog/article2/naslovna-min-855x500.webp';
import najpopularnijeBoje from '../../assets/blog/article3/bele_zavese-1024x538.webp';
import neprijatniMirisi from '../../assets/blog/article4/image00002-855x500.webp';
import blog5 from '../../assets/blog/article5/1.webp';
import blog6 from '../../assets/blog/article6/1.webp';
import blog7 from '../../assets/blog/article7/5.webp';
import blog8 from '../../assets/blog/article8/15.webp';
import blog9 from '../../assets/blog/article9/13.webp';
import blog10 from '../../assets/blog/article10/trakaste1.webp';
import blog11 from '../../assets/blog/article11/1.webp';
import blog12 from '../../assets/blog/article12/1.webp';
import blog13 from '../../assets/blog/article13/1.webp';
import blog14 from '../../assets/blog/article14/1.webp';
import { Helmet } from 'react-helmet';

export default () => {
  return (
    <>
      <Helmet>
        <title>Blog - DaLux.rs</title>
        <meta name="title" content="Blog - DaLux.rs" />
        <meta
          name="description"
          content="Saveti i zanimljivosti u vezi sa dizajnom enterijera, kao i održavanjem različitih tipova zavesa na sajtu Dalux.rs."
        />
        <link rel="canonical" href="https://www.dalux.rs/blog" />

        <meta property="og:title" content="Blog - DaLux.rs" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.dalux.rs/blog" />
        <meta property="og:site_name" content="dalux.rs" />
        <meta
          property="og:description"
          content="Saveti i zanimljivosti u vezi sa dizajnom enterijera, kao i održavanjem različitih tipova zavesa na sajtu Dalux.rs."
        />
        <meta property="og:locale" content="sr_RS" />
      </Helmet>

      <Container>
        <div
          style={{
            color: 'black',
            display: 'flex',
            flexDirection: 'column',
            minHeight: '800px',
            padding: '120px 0',
            alignItems: 'center',
          }}
        >
          <h1>Blog</h1>

          <div className="blogGrid">
            <BlogItem
              onClick={() => {
                window.location =
                  '/blog/sve-sto-treba-da-znate-o-zebra-zavesama-sa-maskom-bez-maske-cena-prednost';
              }}
              // date={'20. jul, 2024.'}
              title={`Sve što treba da znate o zebra zavesama: Cena, prednosti i modeli sa
          maskom i bez maske`}
              content={`Zebra zavese su sve popularaniji izbor kada se radi o modernom
            dizajnu enterijera, što svoje funkcionalnosti, što zbog estetike.
            Zebra zavese sa maskom imaju prednost kada se radi o estetici...`}
              imageUrl={blog14}
            />

            <BlogItem
              onClick={() => {
                window.location =
                  '/blog/kako-zip-sistem-moze-unaprediti-vas-spoljasnji-prostor';
              }}
              // date={'20. jul, 2024.'}
              title={`Kako zip sistem može unaprediti vaš spoljašnji prostor?`}
              content={`Zip sistemi su sve popularnii izbor za spoljašnji prostor zbog
            njihove funkcionalnosti i svestranosti. Zip sistemi pružaju primarno
            zaštitu od sunčeve svetlosti a zatim i od kiše i vetra...`}
              imageUrl={blog13}
            />

            <BlogItem
              onClick={() => {
                window.location =
                  '/blog/plafonske-garnisne-resenje-za-svaki-plafon';
              }}
              // date={'20. jul, 2024.'}
              title={`Plafonske garnišne: Praktična rešenja za svaki prostor`}
              content={`Garnišne za plafon su sve popularniji izbor zbog svoje praktičnosti i estetske privlačnosti...`}
              imageUrl={blog12}
            />

            <BlogItem
              onClick={() => {
                window.location =
                  '/blog/kako-odabrati-moderne-zavese-za-dnevnu-sobu';
              }}
              // date={'5. jul, 2024.'}
              title={`Kako odabrati moderne zavese za dnevnu sobu?`}
              content={`Zavese igraju ključnu ulogu u dekoraciji dnevne sobe, dodajući estetsku vrednost i funkcionalnost prostoru...`}
              imageUrl={blog11}
            />

            <BlogItem
              onClick={() => {
                window.location =
                  '/blog/zasto-su-trakaste-zavese-savrsene-za-kancelarije';
              }}
              // date={'23. jun, 2024.'}
              title={`Zašto su trakaste zavese savršene za kancelarije?`}
              content={`U današnjem poslovnom svetu, dizajn kancelarije igra ključnu ulogu u produktivnosti i zadovoljstvu zaposlenih...`}
              imageUrl={blog10}
            />

            <BlogItem
              onClick={() => {
                window.location =
                  '/blog/da-li-sve-zavese-u-sobi-treba-da-budu-identicne';
              }}
              // date={'10. jun, 2024.'}
              title={`Da li sve zavese u sobi treba da budu identične?`}
              content={`U enterijeru, boje igraju ključnu ulogu u stvaranju atmosfere i izražavanju stila. Kada se radi o zavesama, izbor prave boje može značajno...`}
              imageUrl={blog9}
            />

            <BlogItem
              onClick={() => {
                window.location =
                  '/blog/rimske-zavese-savrsen-spoj-stila-i-funkcionalnosti';
              }}
              // date={'15. maj, 2024.'}
              title={`Rimske zavese - Savršen spoj stila i funkcionalnosti`}
              content={`Rimske zavese su klasičan proizvod koji se kroz vekove nije samo očuvao, već je i evoluirao...`}
              imageUrl={blog8}
            />

            <BlogItem
              onClick={() => {
                window.location =
                  '/blog/da-li-ste-znali-da-boja-zavese-koju-odaberete-govori-mnogo-o-vasoj-licnosti';
              }}
              // date={'23. april, 2024.'}
              title={`Da li ste znali da boja zavese koju odaberete govori mnogo o vašoj ličnosti?`}
              content={`Boje često igraju ključnu ulogu u našem svakodnevnom životu, utičući na naše raspoloženje, emocije i čak naš karakter...`}
              imageUrl={blog7}
            />

            <BlogItem
              onClick={() => {
                window.location =
                  '/blog/ovo-je-5-razloga-zbog-kojih-svaki-dom-treba-da-ima-komarnike';
              }}
              // date={'10. mart, 2024.'}
              title={`Ovo je 5 razloga zbog kojih svaki dom treba da ima komarnike`}
              content={`Leto je velikom broju ljudi omiljeno godišnje doba, ali često dolazi s jednim nepoželjnim gostom - komarcima...`}
              imageUrl={blog6}
            />

            <BlogItem
              onClick={() => {
                window.location =
                  '/blog/upustite-se-u-putovanje-kroz-najzanimljivije-dizajne-tapeta';
              }}
              // date={'21. februar, 2024.'}
              title={`Upustite se u putovanje kroz najzanimljivije dizajne tapeta`}
              content={`U svetu dizajna enterijera, tapete su kao čarobna četkica koja transformiše običan zid u platno puno maštovitih priča... `}
              imageUrl={blog5}
            />

            <BlogItem
              onClick={() => {
                window.location =
                  '/blog/saznajte-kako-da-uklonite-neprijatne-mirise-iz-vasih-draperija-i-kako-ih-treba-odrzavati';
              }}
              // date={'20. januar, 2024.'}
              title={`Saznajte kako da uklonite neprijatne mirise iz vaših draperija i kako ih treba održavati`}
              content={`Koliko puta smo ušli u neki lepo dekorisani prostor koji je imao draperije koje se “osećaju”. Ova činjenica kvari opšti utisak i odbija ljude...`}
              imageUrl={neprijatniMirisi}
            />

            <BlogItem
              onClick={() => {
                window.location =
                  '/blog/predstavljamo-vam-6-najpopularnijih-boja-zavesa';
              }}
              // date={'15. decembar, 2023.'}
              title={`Predstavljamo Vam 6 Najpopularnijih Boja Zavesa`}
              content={`Bez obzira na veličinu i izgled vaših prozora, izabrati odgovarajuću boju zavesa može biti, donekle, zahtevan posao...`}
              imageUrl={najpopularnijeBoje}
            />

            <BlogItem
              onClick={() => {
                window.location =
                  '/blog/kako-da-odaberete-odgovarajucu-boju-zavesa-i-draperija-za-vasu-sobu';
              }}
              // date={'18. novembar, 2023.'}
              title={`Kako da odaberete odgovarajuću boju zavesa i draperija za vašu sobu`}
              content={`Odabrati prave zavese za vaš stan, kuću sobu ili poslovni prostor može biti izuzetno naporan zadatak...`}
              imageUrl={bojaZavesa}
            />

            <BlogItem
              onClick={() => {
                window.location =
                  '/blog/5-saveta-da-vasa-soba-bude-veca-pomocu-zavesa';
              }}
              // date={'12. oktobar, 2023.'}
              title={`5 saveta da vaša soba bude veća pomoću zavesa`}
              content={`Svako od nas je u jednom trenutku poželeo da njegov životni prostor
          bude znatno prostraniji nego što jeste..`}
              imageUrl={petSaveta}
            />
          </div>
        </div>
      </Container>
    </>
  );
};
