import React from 'react';
import { Faq, Slider } from '../../components';

import SlideFirst from '../../assets/zip/1.png';
import SlideSecond from '../../assets/zip/2.webp';
import SlideThird from '../../assets/zip/3.webp';
import SlideFourth from '../../assets/zip/4.webp';
import SlideFifth from '../../assets/zip/5.webp';
import SlideSixth from '../../assets/zip/6.webp';
import SlideSeventh from '../../assets/zip/7.webp';

import SlideFirstPhone from '../../assets/zip_mobile/zip1.webp';
import SlideSecondPhone from '../../assets/zip_mobile/zip2.webp';
import SlideThirdPhone from '../../assets/zip_mobile/zip3.webp';
import SlideFourthPhone from '../../assets/zip_mobile/zip4.webp';
import SlideFifthPhone from '../../assets/zip_mobile/zip5.webp';
import SlideSixthPhone from '../../assets/zip_mobile/zip6.webp';
import SlideSeventhPhone from '../../assets/zip_mobile/zip7.webp';
import { Helmet } from 'react-helmet';

const desktop = window.innerWidth > 600 ? true : false;

const SLIDERS = [
  {
    title: 'Zip sistemi',
    message: 'Funkcionalno rešenje za zaštitu od sunca.',
    image: desktop ? SlideFirst : SlideFirstPhone,
  },
  {
    title: 'Zip sistemi',
    message: 'Мaterijali su otporni na sve vremenske uslove.',
    image: desktop ? SlideSecond : SlideSecondPhone,
  },
  {
    title: 'Zip sistemi',
    message: 'Efikasna zaštita od UV zraka.',
    image: desktop ? SlideThird : SlideThirdPhone,
  },
  {
    title: 'Zip sistemi',
    message: 'Pomeraju se gore-dole i pomažu kod privatnosti.',
    image: desktop ? SlideFourth : SlideFourthPhone,
  },
  {
    title: 'Zip sistemi',
    message: 'Koriste se u domovima i poslovnim objekatima.',
    image: desktop ? SlideFifth : SlideFifthPhone,
  },
  {
    title: 'Zip sistemi',
    message: 'Kontrolišu se daljinskim, senzorom i tajmerom.',
    image: desktop ? SlideSixth : SlideSixthPhone,
  },
  {
    title: 'Zip sistemi',
    message: 'Smanjuju buku koja dolazi spolja i utiču na komfor.',
    image: desktop ? SlideSeventh : SlideSeventhPhone,
  },
];

const ZipSistemi = () => {
  return (
    <>
      <Helmet>
        <title>Zip sistemi - Spoljne rolo zavese - DaLux</title>
        <meta
          name="title"
          content="Zip sistemi - Spoljne rolo zavese - DaLux"
        />
        <meta
          name="description"
          content="Zip sistemi ili spoljne rolo zavese su moderan i praktičan način zaštite od sunca. Posetite sajt Dalux.rs i pogledajte ponudu."
        />
        <meta name="robots" content="max-image-preview:large" />
        <link rel="canonical" href="https://www.dalux.rs/zip-sistemi" />

        <meta
          property="og:title"
          content="Zip sistemi - Spoljne rolo zavese - DaLux"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.dalux.rs/zip-sistemi" />
        <meta property="og:site_name" content="dalux.rs" />
        <meta
          property="og:description"
          content="Zip sistemi ili spoljne rolo zavese su moderan i praktičan način zaštite od sunca. Posetite sajt Dalux.rs i pogledajte ponudu."
        />
        <meta property="og:locale" content="sr_RS" />
        <meta
          property="og:image"
          content="https://www.dalux.rs/static/media/1.e6c5aaa6db7aa0a631b5.png"
        />
        <meta property="og:image:width" content="2880" />
        <meta property="og:image:height" content="1620" />

        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Zip sistemi - Spoljne rolo zavese - DaLux",
        "url": "https://www.dalux.rs/zip-sistemi",
        "description": "Zip sistemi ili spoljne rolo zavese su moderan i praktičan način zaštite od sunca. Posetite sajt Dalux.rs i pogledajte ponudu.",
        "breadcrumb": {
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Početna",
              "item": "https://www.dalux.rs"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "Zip sistemi",
              "item": "https://www.dalux.rs/zip-sistemi"
            }
          ]
        }
      }
    `}
        </script>
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "Product",
        "name": "Zip sistemi",
        "image": "https://www.dalux.rs/static/media/1.e6c5aaa6db7aa0a631b5.png",
        "description": "Zip sistemi ili spoljne rolo zavese su moderan i praktičan način zaštite od sunca. Posetite sajt Dalux.rs i pogledajte ponudu.",
        "brand": {
          "@type": "Brand",
          "name": "DaLux"
        },
        "offers": {
          "@type": "Offer",
          "priceCurrency": "RSD",
          "priceValidUntil": "2024-12-31",
          "itemCondition": "https://schema.org/NewCondition",
          "availability": "https://schema.org/InStock",
          "seller": {
            "@type": "Organization",
            "name": "DaLux"
          },
          "url": "https://www.dalux.rs/zip-sistemi",
          "description": "Kontaktirajte nas za detalje o ceni."
        }
      }
    `}
        </script>
      </Helmet>

      <Slider sliders={SLIDERS} />

      <Faq
        questions={[
          {
            title: 'Koja je cena ZipScreen sistema?',
            message:
              'Cenovnik za ove sisteme je tabelaran stoga su nam potrebne makar okvirne dimenzije širine i visine koju želite kako bismo mogli da Vam napravimo okviran obračun.',
          },
          {
            title: 'Da li je ZipScreen sistem otporan na sve vremenske uslove?',
            message: 'Ovi sistemi su otporni na sve vremenske uslove.',
          },
          {
            title:
              'Da li je moguće da ZipScreen sistem radi bez elektromotora?',
            message:
              'U našoj ponudi ZipScreen sistemi se isključivo izrađuju na Somfy motorni pogon.',
          },
          {
            title:
              'Koji je proizvođač elektromotora koji se koristi za ZipScreen sisteme?',
            message:
              'Za ZipScreen sisteme koristimo isključivo Somfy motore dizajnirane u Francuskoj koji garantuju optimalan kvalitet, performanse i dugotrajnost.',
          },
          {
            title: 'Kolika je garancija na Zip sisteme?',
            message:
              'Za ZipScreen sisteme izdajemo garanciju od 5 godina od momenta instalacije.',
          },
          {
            title: 'Koliki je rok isporuke?',
            message:
              'Rok isporuke za zip sisteme je 2-4 nedelje od momenta kada se uplati avans.',
          },
        ]}
        faqImg={SlideFirstPhone}
      />
    </>
  );
};

export default ZipSistemi;
