import React from 'react';
import { Faq, Slider } from '../../components';

import SlideFirst from '../../assets/rolo/1.webp';
import SlideSecond from '../../assets/rolo/2.webp';
import SlideThird from '../../assets/rolo/3.webp';
import SlideFourth from '../../assets/rolo/4.webp';
import SlideFifth from '../../assets/rolo/5.webp';
import SlideSixth from '../../assets/rolo/6.webp';

import SlideFirstMobile from '../../assets/rolo_mobile/rolo1.webp';
import SlideSecondMobile from '../../assets/rolo_mobile/rolo2.webp';
import SlideThirdMobile from '../../assets/rolo_mobile/rolo3.webp';
import SlideFourthMobile from '../../assets/rolo_mobile/rolo4.webp';
import SlideFifthMobile from '../../assets/rolo_mobile/rolo5.webp';
import SlideSixthMobile from '../../assets/rolo_mobile/rolo6.webp';
import { Helmet } from 'react-helmet';

const desktop = window.innerWidth > 600 ? true : false;

const SLIDERS = [
  {
    title: 'Rolo zavese',
    message: 'Minimalistički stil pruža eleganciju i estetiku.',
    image: desktop ? SlideFirst : SlideFirstMobile,
  },
  {
    title: 'Rolo zavese',
    message: 'Kontrolišu količinu svetlosti u prostoriji.',
    image: desktop ? SlideSecond : SlideSecondMobile,
  },
  {
    title: 'Rolo zavese',
    message: 'Dostupne su u različitim bojama i dezenima.',
    image: desktop ? SlideThird : SlideThirdMobile,
  },
  {
    title: 'Rolo zavese',
    message: 'Minimalno održavanje i brisanje održaće ih čistim.',
    image: desktop ? SlideFourth : SlideFourthMobile,
  },
  {
    title: 'Rolo zavese',
    message: 'Lako se montiraju i ne zahtevaju puno prostora.',
    image: desktop ? SlideFifth : SlideFifthMobile,
  },
  {
    title: 'Rolo zavese',
    message: 'Pružaju sigurnost i sprečavaju poglede spolja.',
    image: desktop ? SlideSixth : SlideSixthMobile,
  },
];

const Rolo = () => {
  return (
    <>
      <Helmet>
        <title>Rolo zavese - Kvalitet po meri svakog prozora - DaLux</title>
        <meta
          name="title"
          content="Rolo zavese - Kvalitet po meri svakog prozora - DaLux"
        />
        <meta
          name="description"
          content="Rolo zavese visokog kvaliteta izrađene po Vašoj meri. Veliki izbor dezena. Besplatno merenje i ugradnja na teritoriji Beograda."
        />
        <meta name="robots" content="max-image-preview:large" />
        <link rel="canonical" href="https://www.dalux.rs/rolo-zavese" />

        <meta
          property="og:title"
          content="Rolo zavese - Kvalitet po meri svakog prozora - DaLux"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.dalux.rs/rolo-zavese" />
        <meta property="og:site_name" content="dalux.rs" />
        <meta
          property="og:description"
          content="Rolo zavese visokog kvaliteta izrađene po Vašoj meri. Veliki izbor dezena. Besplatno merenje i ugradnja na teritoriji Beograda."
        />
        <meta property="og:locale" content="sr_RS" />
        <meta
          property="og:image"
          content="https://www.dalux.rs/static/media/1.0a4a1025aac3d5cfb67d.webp"
        />
        <meta property="og:image:width" content="2880" />
        <meta property="og:image:height" content="1620" />

        <script type="application/ld+json">
          {`
  {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "url": "https://www.dalux.rs/rolo-zavese",
    "name": "Rolo zavese - Kvalitet po meri svakog prozora - DaLux",
    "description": "Rolo zavese visokog kvaliteta izrađene po Vašoj meri. Veliki izbor dezena. Besplatno merenje i ugradnja na teritoriji Beograda.",
    "breadcrumb": {
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Početna",
          "item": "https://www.dalux.rs"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "Rolo zavese",
          "item": "https://www.dalux.rs/rolo-zavese"
        }
      ]
    }
  }
  `}
        </script>

        <script type="application/ld+json">
          {`
  {
    "@context": "https://schema.org",
    "@type": "Product",
    "name": "Rolo zavese",
    "image": "https://www.dalux.rs/static/media/1.0a4a1025aac3d5cfb67d.webp",
    "description": "Rolo zavese visokog kvaliteta izrađene po Vašoj meri. Veliki izbor dezena. Besplatno merenje i ugradnja na teritoriji Beograda.",
    "url": "https://www.dalux.rs/rolo-zavese",
    "offers": {
      "@type": "Offer",
      "priceCurrency": "RSD",
      "priceValidUntil": "2024-12-31",
      "itemCondition": "https://schema.org/NewCondition",
      "availability": "https://schema.org/InStock",
      "seller": {
        "@type": "Organization",
        "name": "DaLux"
      }
    }
  }
  `}
        </script>
      </Helmet>

      <Slider sliders={SLIDERS} />

      <Faq
        questions={[
          {
            title: 'Kako se uzimaju mere rolo zavesa?',
            message:
              'Ukoliko ne znate kako da uzmete dimenzije, pozovite nas i mi ćemo Vam sve objasniti! U zavisnosti od toga koji mehanizam želite.',
          },
          {
            title: 'Koje su cene rolo zavesa?',
            message:
              'Cene zavise od kategorije platna kao i sistema montaže koji želite.',
          },
          {
            title: 'Koji je način održavanja rolo zavesa?',
            message:
              'Zebra i rolo platna su antistatična i antibakterijska što znaci da ne skupljaju prašinu, tako da Vam preostaje samo da ih prebrišete vlažnom krpom sa vremena na vreme.',
          },
          {
            title:
              'Da li se cena rolo zavesa kreće po metru kvadratnom ili po komadu?',
            message: 'Cene rolo i zebra zavesa se kreću po kvadratnom metru.',
          },
          {
            title:
              'Zašto je neophodno pustiti platno da pređe sa leve i desne strane od špaletne 5-10 centimetara?',
            message:
              'Uvek preporučujemo da proširite zavesu u odnosu na špaletnu prozora kako ne bi prodirala svetlost u prostoriju. Ovo pravilo važi za sisteme koji se montiraju na zid ili plafon.',
          },
          {
            title: 'Koliki je rok isporuke?',
            message:
              'Rok isporuke za rolo zavese je 2-4 nedelje od momenta kada se uplati avans.',
          },
        ]}
        faqImg={SlideFirstMobile}
      />
    </>
  );
};

export default Rolo;
