import React from 'react';
import { Faq, Slider } from '../../components';

import SlideFirst from '../../assets/tapete/1.png';
import SlideSecond from '../../assets/tapete/2.png';
import SlideThird from '../../assets/tapete/3.webp';
import SlideFourth from '../../assets/tapete/4.webp';
import SlideFifth from '../../assets/tapete/5.webp';
import SlideSixth from '../../assets/tapete/6.webp';
import SlideSeventh from '../../assets/tapete/7.webp';
import SlideEighth from '../../assets/tapete/8.webp';
import SlideNinth from '../../assets/tapete/9.webp';

import SlideFirstPhone from '../../assets/tapete_mobile/tap1.webp';
import SlideSecondPhone from '../../assets/tapete_mobile/tap2.webp';
import SlideThirdPhone from '../../assets/tapete_mobile/tap3.webp';
import SlideFourthPhone from '../../assets/tapete_mobile/tap4.webp';
import SlideFifthPhone from '../../assets/tapete_mobile/tap5.webp';
import SlideSixthPhone from '../../assets/tapete_mobile/tap6.webp';
import SlideEighthPhone from '../../assets/tapete_mobile/tap8.webp';
import SlideNinthPhone from '../../assets/tapete_mobile/tap9.webp';
import { Helmet } from 'react-helmet';

const desktop = window.innerWidth > 600 ? true : false;

const SLIDERS = [
  {
    title: 'Tapete',
    message: 'Različite boje, teksture i dezeni.',
    image: desktop ? SlideFirst : SlideFirstPhone,
  },
  {
    title: 'Tapete',
    message: 'Brzo i lako se postavljaju na različite zidove.',
    image: desktop ? SlideSecond : SlideSecondPhone,
  },
  {
    title: 'Tapete',
    message: 'Održavaju se povremenim brisanjem mokrom krpom.',
    image: desktop ? SlideThird : SlideThirdPhone,
  },
  {
    title: 'Tapete',
    message: 'Pokrivaju neravnine i oštećenja na zidovima.',
    image: desktop ? SlideFourth : SlideFourthPhone,
  },
  {
    title: 'Tapete',
    message: 'Sprečavaju pojavu vlage i buđi na zidovima.',
    image: desktop ? SlideFifth : SlideFifthPhone,
  },
  {
    title: 'Tapete',
    message: 'Kvalitetni materijali garantuju dugotrajnost.',
    image: desktop ? SlideSixth : SlideSixthPhone,
  },
  {
    title: 'Tapete',
    message: 'Duža postojanost boja od farbanja zidova.',
    image: SlideSeventh,
  },
  {
    title: 'Tapete',
    message: 'Štite zidove od oštećenja ili ogrebotina.',
    image: desktop ? SlideEighth : SlideEighthPhone,
  },
  {
    title: 'Tapete',
    message: 'Mnoštvo dezena može poslužiti kao inspiracija.',
    image: desktop ? SlideNinth : SlideNinthPhone,
  },
];
const Tapete = () => {
  return (
    <>
      <Helmet>
        <title>Tapete - DaLux.rs</title>
        <meta name="title" content="Tapete - DaLux.rs" />
        <meta
          name="description"
          content="Veliki izbor kvalitetnih tapeta različitih boja i dezena. Posetite sajt Dalux.rs i pogledajte ponudu."
        />
        <meta name="robots" content="max-image-preview:large" />
        <link rel="canonical" href="https://www.dalux.rs/tapete" />

        <meta property="og:title" content="Tapete - DaLux.rs" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.dalux.rs/tapete" />
        <meta property="og:site_name" content="dalux.rs" />
        <meta
          property="og:description"
          content="Veliki izbor kvalitetnih tapeta različitih boja i dezena. Posetite sajt Dalux.rs i pogledajte ponudu."
        />
        <meta property="og:locale" content="sr_RS" />
        <meta
          property="og:image"
          content="https://www.dalux.rs/static/media/1.17da6af0eb08fa35a109.png"
        />
        <meta property="og:image:width" content="2880" />
        <meta property="og:image:height" content="1620" />

        <script type="application/ld+json">
          {`
    {
      "@context": "https://schema.org",
      "@type": "WebPage",
      "name": "Tapete",
      "description": "Veliki izbor kvalitetnih tapeta različitih boja i dezena. Posetite sajt Dalux.rs i pogledajte ponudu.",
      "url": "https://www.dalux.rs/tapete",
      "breadcrumb": {
        "@type": "BreadcrumbList",
        "itemListElement": [
          {
            "@type": "ListItem",
            "position": 1,
            "name": "Početna",
            "item": "https://www.dalux.rs/"
          },
          {
            "@type": "ListItem",
            "position": 2,
            "name": "Tapete",
            "item": "https://www.dalux.rs/tapete"
          }
        ]
      }
    }
    `}
        </script>

        <script type="application/ld+json">
          {`
    {
      "@context": "https://schema.org",
      "@type": "Product",
      "name": "Tapete",
      "description": "Veliki izbor kvalitetnih tapeta različitih boja i dezena. Posetite sajt Dalux.rs i pogledajte ponudu.",
      "image": "https://www.dalux.rs/static/media/1.17da6af0eb08fa35a109.png",
      "url": "https://www.dalux.rs/tapete",
      "brand": {
        "@type": "Brand",
        "name": "DaLux"
      },
      "offers": {
        "@type": "Offer",
        "priceCurrency": "RSD",
        "priceValidUntil": "2024-12-31",
        "itemCondition": "https://schema.org/NewCondition",
        "availability": "https://schema.org/InStock",
        "seller": {
          "@type": "Organization",
          "name": "DaLux"
        }
      }
    }
    `}
        </script>
      </Helmet>

      <Slider sliders={SLIDERS} />

      <Faq
        questions={[
          {
            title: 'Da li su tapete samolepljive?',
            message:
              'Sve tapete u našoj ponudi su na bazi lepka i nisu samolepljive.',
          },
          {
            title: 'Da li imate usluge lepljenja tapeta?',
            message:
              'Kao i za sve naše proizvode tako i za tapete u ponudi imamo i uslugu lepljenja istih.',
          },
          {
            title: 'Da li se tapete prodaju po kvadratnom metru?',
            message:
              'Dalux tapete se prodaju u rolnama sa kojima može da se prekrije do 5 kvadratnih metara.',
          },
          {
            title: 'Koji je rok isporuke za tapete?',
            message:
              'Rok isporuke za tapete je 2-3 radna dana ukoliko su na lageru.',
          },
          {
            title: 'Da li postoje vodootporne tapete?',
            message:
              'U našoj širokoj ponudi imamo i tapete koje su otporne na vodu.',
          },
        ]}
        faqImg={SlideFirstPhone}
      />
    </>
  );
};

export default Tapete;
