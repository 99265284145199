import React from 'react';
import { Faq, Slider } from '../../components';

import SlideFirst from '../../assets/zebra/1.webp';
import SlideSecond from '../../assets/zebra/2.png';
import SlideThird from '../../assets/zebra/3.png';
import SlideFourth from '../../assets/zebra/4.webp';
import SlideFifth from '../../assets/zebra/5.webp';
import SlideSixth from '../../assets/zebra/6.webp';
import SlideEighth from '../../assets/zebra/7.webp';
import SlideSeventh from '../../assets/zebra/9.webp';

import SlideFirstMobile from '../../assets/zebra_mobile/zebra1.webp';
import SlideSecondMobile from '../../assets/zebra_mobile/zebra2.webp';
import SlideThirdMobile from '../../assets/zebra_mobile/zebra3.webp';
import SlideFourthMobile from '../../assets/zebra_mobile/zebra4.webp';
import SlideFifthMobile from '../../assets/zebra_mobile/zebra5.webp';
import SlideSixthMobile from '../../assets/zebra_mobile/zebra6.webp';
import SlideSeventhMobile from '../../assets/zebra_mobile/zebra7.webp';
import { Helmet } from 'react-helmet';

const desktop = window.innerWidth > 600 ? true : false;

const SLIDERS = [
  {
    title: 'Zebra zavese',
    message: 'Moderno rešenje za zaštitu od sunca.',
    image: desktop ? SlideFirst : SlideFirstMobile,
  },
  {
    title: 'Zebra zavese',
    message: 'Pogodne su za dnevne, spavaće sobe i kancelarije.',
    image: desktop ? SlideSecond : SlideSecondMobile,
  },
  {
    title: 'Zebra zavese',
    message: 'Dostupne su u različitim bojama i dezenima.',
    image: desktop ? SlideThird : SlideThirdMobile,
  },
  {
    title: 'Zebra zavese',
    message: 'Kombinacija su estetike i funkcionalnosti.',
    image: desktop ? SlideFourth : SlideFourthMobile,
  },
  {
    title: 'Zebra zavese',
    message: 'Kvalitetni materijali imaju dug vek trajanja.',
    image: desktop ? SlideFifth : SlideFifthMobile,
  },
  {
    title: 'Zebra zavese',
    message: 'Jednostavno se pomeraju gore-dole.',
    image: desktop ? SlideSixth : SlideSixthMobile,
  },
  {
    title: 'Zebra zavese',
    message: 'Možete ih montirati i samostalno.',
    image: desktop ? SlideSeventh : SlideSeventhMobile,
  },
  {
    title: 'Zebra zavese',
    message: 'Smanjuju cirkulaciju prašine u prostoru.',
    image: SlideEighth,
  },
];

const Zebra = () => {
  return (
    <>
      <Helmet>
        <title>Zebra zavese - Zavese dan noć - DaLux</title>
        <meta name="title" content="Zebra zavese - Zavese dan noć - DaLux" />
        <meta
          name="description"
          content="Zebra zavese su idealno rešenje za zaštitu od svetlosti uz elegantan i moderan dizajn. Pogledajte kompletnu ponudu na sajtu Dalux.rs."
        />
        <meta name="robots" content="max-image-preview:large" />
        <link rel="canonical" href="https://www.dalux.rs/zebra-zavese" />

        <meta
          property="og:title"
          content="Zebra zavese - Zavese dan noć - DaLux"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.dalux.rs/zebra-zavese" />
        <meta property="og:site_name" content="dalux.rs" />
        <meta
          property="og:description"
          content="Zebra zavese su idealno rešenje za zaštitu od svetlosti uz elegantan i moderan dizajn. Pogledajte kompletnu ponudu na sajtu Dalux.rs."
        />
        <meta property="og:locale" content="sr_RS" />
        <meta
          property="og:image"
          content="https://www.dalux.rs/static/media/1.73ed825176a4b7dacdf9.webp"
        />
        <meta property="og:image:width" content="2880" />
        <meta property="og:image:height" content="1620" />

        <script type="application/ld+json">
          {`
    {
      "@context": "https://schema.org",
      "@type": "WebPage",
      "name": "Zebra zavese - Zavese dan noć - DaLux",
      "url": "https://www.dalux.rs/zebra-zavese",
      "description": "Zebra zavese su idealno rešenje za zaštitu od svetlosti uz elegantan i moderan dizajn. Pogledajte kompletnu ponudu na sajtu Dalux.rs.",
      "breadcrumb": {
        "@type": "BreadcrumbList",
        "itemListElement": [
          {
            "@type": "ListItem",
            "position": 1,
            "name": "Početna",
            "item": "https://www.dalux.rs/"
          },
          {
            "@type": "ListItem",
            "position": 2,
            "name": "Zebra zavese",
            "item": "https://www.dalux.rs/zebra-zavese"
          }
        ]
      }
    }
    `}
        </script>

        <script type="application/ld+json">
          {`
    {
      "@context": "https://schema.org",
      "@type": "Product",
      "name": "Zebra zavese",
      "image": "https://www.dalux.rs/images/shop/zebra/AbuDhabi.webp",
      "description": "Zebra zavese su idealno rešenje za zaštitu od svetlosti uz elegantan i moderan dizajn. Pomeraju se gore-dole, namotavanjem na osovinu. Prilikom pomeranja zavese dolazi do različite svetlopropusnosti. Na ovaj način jednostavno kontrolišete količinu svetla u prostoriji i štitite se od neželjenih pogleda. Rok isporuke: 5-7 dana. Fiskalni račun se dobija u paketu. Troškove dostave snosi kupac.",
      "url": "https://www.dalux.rs/shop?product=zebra-zavese",
      "offers": {
        "@type": "Offer",
        "priceCurrency": "RSD",
        "priceValidUntil": "2024-12-31",
        "itemCondition": "https://schema.org/NewCondition",
        "availability": "https://schema.org/InStock",
        "seller": {
          "@type": "Organization",
          "name": "DaLux"
        }
      }
    }
    `}
        </script>
      </Helmet>

      <Slider sliders={SLIDERS} />

      <Faq
        questions={[
          {
            title: 'Kako se uzimaju mere zebra zavesa?',
            message:
              'Ukoliko ne znate kako da uzmete dimenzije, pozovite nas i mi ćemo Vam sve objasniti! U zavisnosti od toga koji mehanizam želite.',
          },
          {
            title: 'Kako se kreću cene zebra zavesa?',
            message:
              'Cene zavise od kategorije platna kao i sistema montaže koji želite.',
          },
          {
            title: 'Koji je način održavanja zebra zavesa?',
            message:
              'Zebra i rolo platna su antistatična i antibakterijska što znaci da ne skupljaju prašinu, tako da Vam preostaje samo da ih prebrišete vlažnom krpom sa vremena na vreme.',
          },
          {
            title:
              'Da li mini zebra zavese na kačenje možemo sami da montiramo?',
            message:
              'Ovo je verovatno i najprostija montaža koju može svako da izvede. Potrebno Vam je svega 30 sekundi da montirate mini zebra zavesu.',
          },
          {
            title:
              'Da li se cena zebra zavesa kreće po metru kvadratnom ili po komadu?',
            message: 'Cene rolo i zebra zavesa se kreću po kvadratnom metru.',
          },
          {
            title:
              'Zašto je neophodno pustiti platno da pređe sa leve i desne strane od špaletne 5-10 centimetara?',
            message:
              'Uvek preporučujemo da proširite zavesu u odnosu na špaletnu prozora kako ne bi prodirala svetlost u prostoriju. Ovo pravilo važi za sisteme koji se montiraju na zid ili plafon.',
          },
          {
            title: 'Koliki je rok isporuke?',
            message:
              'Rok isporuke za zebra zavese je 2-4 nedelje od momenta kada se uplati avans.',
          },
          {
            title: 'Šta su mini zebra zavese?',
            message:
              'Mini zebra zavese su kompaktne verzije standardnih zebra zavesa čija montaža ne zahteva bušenje stolarije. Lako se održavaju i prilagođavaju različitim tipovima prozora.',
          },
          {
            title:
              'Koja je razlika između mini zebra zavesa sa maskom i bez maske?',
            message:
              'Mini zebra zavese sa maskom imaju ugrađenu masku (pokrivač) na gornjem delu koji skriva mehanizam za namotavanje i samu rolnu tkanine, dok mini zebra bez maske imaju otvoren pristup mehanizmu i tkanini.',
          },
          {
            title: 'Koja je razlika između trakastih zavesa i zebra zavesa?',
            message:
              'Glavna razlika je u dizajnu i kontroli svetlosti. Trakaste zavese imaju vertikalne trake koje se rotiraju za kontrolu svetlosti, dok zebra zavese imaju horizontalne trake. Trakaste zavese su idealne za poslovne prostore, dok zebra zavese pružaju moderan izgled i fleksibilnost za kućne prostore.',
          },
        ]}
        faqImg={SlideFirstMobile}
      />
    </>
  );
};

export default Zebra;
