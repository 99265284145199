import './App.css';
import { Header } from './containers';
import { Navbar, Footer } from './components';
import { Route, Routes, useLocation } from 'react-router-dom';
import HomePage from './containers/homePage/HomePage';
import ZaveseiDraperije from './containers/zaveseIDraperije/ZaveseiDraperije';
import HotelskiProgram from './containers/hotelskiProgram/HotelskiProgram';
import KorporativniProgram from './containers/korporativniProgram/KorporativniProgram';
import ProgramZaArhitekte from './containers/programZaArhitekte/ProgramZaArhitekte.js';
import ServisZavesa from './containers/servisZavesa/ServisZavesa';
import Plise from './containers/plise/Plise';
import Zebra from './containers/zebra/Zebra';
import Rolo from './containers/rolo/Rolo';
import Rimske from './containers/rimske/Rimske';
import Panelne from './containers/panelne/Panelne';
import DrveniVenecijaneri from './containers/drveniVenecijaneri/DrveniVenecijaneri';
import ZipSistemi from './containers/zipSistemi/ZipSistemi';
import Tapete from './containers/tapete/Tapete';
import Komarnici from './containers/komarnici/Komarnici';
import Blog from './containers/blog/Blog';
import BlogArticle1 from './containers/blog/BlogArticle1';
import BlogArticle2 from './containers/blog/BlogArticle2';
import BlogArticle3 from './containers/blog/BlogArticle3';
import BlogArticle4 from './containers/blog/BlogArticle4';
import BlogArticle5 from './containers/blog/BlogArticle5';
import BlogArticle6 from './containers/blog/BlogArticle6';
import BlogArticle7 from './containers/blog/BlogArticle7';
import BlogArticle8 from './containers/blog/BlogArticle8';
import BlogArticle9 from './containers/blog/BlogArticle9';
import BlogArticle10 from './containers/blog/BlogArticle10';
import BlogArticle11 from './containers/blog/BlogArticle11';
import BlogArticle12 from './containers/blog/BlogArticle12';
import BlogArticle13 from './containers/blog/BlogArticle13';
import BlogArticle14 from './containers/blog/BlogArticle14';
import Login from './components/Login/Login';
import Dashboard from './components/Dashboard/Dashboard';
import { useEffect } from 'react';
import { ShopNew } from './containers/ShopNew/ShopNew';
import { Order } from './containers/Order/Order';
import NotFoundPage from './containers/NotFoundPage/NotFoundPage';
import { CartContext } from './CartContext';
import Zaposlenje from './containers/zaposlenje/CareersPage.jsx';
import AboutUsPage from './containers/aboutUs/AboutUsPage.jsx';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const router = [
  { path: '/', element: <HomePage /> },
  { path: 'zavese-i-draperije', element: <ZaveseiDraperije /> },
  { path: 'hotelski-program', element: <HotelskiProgram /> },
  { path: 'korporativni-program', element: <KorporativniProgram /> },
  { path: 'program-za-arhitekte', element: <ProgramZaArhitekte /> },
  { path: 'servis-zavesa', element: <ServisZavesa /> },
  { path: 'plise-zavese', element: <Plise /> },
  { path: 'zebra-zavese', element: <Zebra /> },
  { path: 'rolo-zavese', element: <Rolo /> },
  { path: 'rimske-zavese', element: <Rimske /> },
  { path: 'panel-zavese', element: <Panelne /> },
  { path: 'drveni-venecijaneri', element: <DrveniVenecijaneri /> },
  { path: 'zip-sistemi', element: <ZipSistemi /> },
  { path: 'tapete', element: <Tapete /> },
  { path: 'komarnici', element: <Komarnici /> },

  { path: 'shop', element: <ShopNew /> },
  { path: 'shop/order/:orderId', element: <Order /> },

  { path: 'blog', element: <Blog /> },
  {
    path: 'blog/5-saveta-da-vasa-soba-bude-veca-pomocu-zavesa',
    element: <BlogArticle1 />,
  },
  {
    path: 'blog/kako-da-odaberete-odgovarajucu-boju-zavesa-i-draperija-za-vasu-sobu',
    element: <BlogArticle2 />,
  },
  {
    path: 'blog/predstavljamo-vam-6-najpopularnijih-boja-zavesa',
    element: <BlogArticle3 />,
  },
  {
    path: 'blog/saznajte-kako-da-uklonite-neprijatne-mirise-iz-vasih-draperija-i-kako-ih-treba-odrzavati',
    element: <BlogArticle4 />,
  },
  {
    path: 'blog/upustite-se-u-putovanje-kroz-najzanimljivije-dizajne-tapeta',
    element: <BlogArticle5 />,
  },
  {
    path: 'blog/ovo-je-5-razloga-zbog-kojih-svaki-dom-treba-da-ima-komarnike',
    element: <BlogArticle6 />,
  },
  {
    path: 'blog/da-li-ste-znali-da-boja-zavese-koju-odaberete-govori-mnogo-o-vasoj-licnosti',
    element: <BlogArticle7 />,
  },
  {
    path: 'blog/rimske-zavese-savrsen-spoj-stila-i-funkcionalnosti',
    element: <BlogArticle8 />,
  },
  {
    path: 'blog/da-li-sve-zavese-u-sobi-treba-da-budu-identicne',
    element: <BlogArticle9 />,
  },
  {
    path: 'blog/zasto-su-trakaste-zavese-savrsene-za-kancelarije',
    element: <BlogArticle10 />,
  },
  {
    path: 'blog/kako-odabrati-moderne-zavese-za-dnevnu-sobu',
    element: <BlogArticle11 />,
  },
  {
    path: 'blog/plafonske-garnisne-resenje-za-svaki-plafon',
    element: <BlogArticle12 />,
  },
  {
    path: 'blog/kako-zip-sistem-moze-unaprediti-vas-spoljasnji-prostor',
    element: <BlogArticle13 />,
  },
  {
    path: 'blog/sve-sto-treba-da-znate-o-zebra-zavesama-sa-maskom-bez-maske-cena-prednost',
    element: <BlogArticle14 />,
  },

  { path: 'zaposlenje', element: <Zaposlenje /> },
  { path: 'o-nama', element: <AboutUsPage /> },

  { path: 'login', element: <Login /> },
  { path: 'dashboard', element: <Dashboard /> },
  { path: '*', element: <NotFoundPage /> },
];

const App = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.querySelector('.wrapper').scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Navbar />
      <Header />
      <div className="wrapper">
        <Routes>
          {router.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <Footer />
      </div>
    </>
  );
};
export default App;
