import React from 'react';
import { Faq, Slider } from '../../components';

import SlideFirst from '../../assets/plise/1.webp';
import SlideSecond from '../../assets/plise/2.png';
import SlideThird from '../../assets/plise/3.png';
import SlideFourth from '../../assets/plise/4.webp';
import SlideFifth from '../../assets/plise/5.webp';
import SlideSixth from '../../assets/plise/6.webp';

import SlideFirstPhone from '../../assets/plise_mobile/plisezavese1.webp';
import SlideSecondPhone from '../../assets/plise_mobile/plisezavese2.webp';
import SlideThirdPhone from '../../assets/plise_mobile/plisezavese3.webp';
import SlideFourthPhone from '../../assets/plise_mobile/plisezavese4.webp';
import SlideFifthPhone from '../../assets/plise_mobile/plisezavese5.webp';
import SlideSixthPhone from '../../assets/plise_mobile/plisezavese6.webp';
import { Helmet } from 'react-helmet';

const desktop = window.innerWidth > 600 ? true : false;

const SLIDERS = [
  {
    title: 'Plise zavese',
    message: 'Savremeno arhitektonsko rešenje.',
    image: desktop ? SlideFirst : SlideFirstPhone,
  },
  {
    title: 'Plise zavese',
    message: 'Pružaju elegantan i moderan izgled.',
    image: desktop ? SlideSecond : SlideSecondPhone,
  },
  {
    title: 'Plise zavese',
    message: 'Prilagođavaju se različitim dimenzijama prozora.',
    image: desktop ? SlideThird : SlideThirdPhone,
  },
  {
    title: 'Plise zavese',
    message: 'Sprečavaju zadržavanje prašine i alergena.',
    image: desktop ? SlideFourth : SlideFourthPhone,
  },
  {
    title: 'Plise zavese',
    message: 'Blokiraju sunčevu svetlost i utiču na temperaturu.',
    image: desktop ? SlideFifth : SlideFifthPhone,
  },
  {
    title: 'Plise zavese',
    message: 'Dostupne su u različitim bojama i dezenima.',
    image: desktop ? SlideSixth : SlideSixthPhone,
  },
];

const Plise = () => {
  return (
    <>
      <Helmet>
        <title>Plise zavese - moderan i jednostavan dizajn - DaLux</title>
        <meta
          name="title"
          content="Plise zavese - moderan i jednostavan dizajn - DaLux"
        />
        <meta
          name="description"
          content="Plise zavese su specifičan tip zavesa koji se mogu prilagoditi svakom prozoru ili vratima. Pogledajte ponudu na sajtu Dalux.rs."
        />
        <meta name="robots" content="max-image-preview:large" />
        <link rel="canonical" href="https://www.dalux.rs/plise-zavese" />

        <meta
          property="og:title"
          content="Plise zavese - moderan i jednostavan dizajn - DaLux"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.dalux.rs/plise-zavese" />
        <meta property="og:site_name" content="dalux.rs" />
        <meta
          property="og:description"
          content="Plise zavese su specifičan tip zavesa koji se mogu prilagoditi svakom prozoru ili vratima. Pogledajte ponudu na sajtu Dalux.rs."
        />
        <meta property="og:locale" content="sr_RS" />
        <meta
          property="og:image"
          content="https://www.dalux.rs/static/media/1.fb5fc1bf029734cfaac4.webp"
        />
        <meta property="og:image:width" content="2880" />
        <meta property="og:image:height" content="1620" />

        <script type="application/ld+json">
          {`
    {
      "@context": "https://schema.org",
      "@type": "WebPage",
      "name": "Plise zavese - moderan i jednostavan dizajn - DaLux",
      "url": "https://www.dalux.rs/plise-zavese",
      "description": "Plise zavese su specifičan tip zavesa koji se mogu prilagoditi svakom prozoru ili vratima. Pogledajte ponudu na sajtu Dalux.rs.",
      "breadcrumb": {
        "@type": "BreadcrumbList",
        "itemListElement": [
          {
            "@type": "ListItem",
            "position": 1,
            "name": "Početna",
            "item": "https://www.dalux.rs/"
          },
          {
            "@type": "ListItem",
            "position": 2,
            "name": "Plise zavese",
            "item": "https://www.dalux.rs/plise-zavese"
          }
        ]
      }
    }
    `}
        </script>

        <script type="application/ld+json">
          {`
    {
      "@context": "https://schema.org",
      "@type": "Product",
      "name": "Plise zavese",
      "image": "https://www.dalux.rs/images/shop/pliseZavese/bela/Svetlo siva - 1401.webp",
      "description": "Plise zavese su specifičan tip zavesa koji se mogu prilagoditi svakom prozoru ili vratima. Pružaju elegantan i moderan izgled. Sprečavaju zadržavanje prašine i alergena u Vašem domu. Blokiraju sunčevu svetlost i utiču na temperaturu. Rok dostave je 7-10 radnih dana. Fiskalni račun se dobija u paketu. Troškove dostave snosi kupac.",
      "url": "https://www.dalux.rs/shop?product=plise-zavese",
      "offers": {
        "@type": "Offer",
        "priceCurrency": "RSD",
        "priceValidUntil": "2024-12-31",
        "itemCondition": "https://schema.org/NewCondition",
        "availability": "https://schema.org/InStock",
        "seller": {
          "@type": "Organization",
          "name": "DaLux"
        }
      }
    }
    `}
        </script>
      </Helmet>

      <Slider sliders={SLIDERS} />

      <Faq
        questions={[
          {
            title: 'Koje su cene plise zavesa?',
            message:
              'Cene plise zavesa se kreću od 4.000rsd do 6.000rsd po kvadratnom metru. Cena zavisi od odabira materijala.',
          },
          {
            title: 'Koja je razlika u materijalima zavesa?',
            message:
              'Materijali su podeljeni u više kategorija. Postoje plain platna koja ce Vas zaštiti od neželjenih pogleda i toplote dok će blackout platno totalno zamračiti prostoriju.',
          },
          {
            title: 'Kako se održavaju plise zavese?',
            message:
              'Materijali od kojih su napravljene ove zavese su antistatični i antibakterijski stoga ih nije potrebno prati. Potrebno je samo prebrisati vlažnom krpom sa vremena na vreme.',
          },
          {
            title: 'Da li možemo sami da izmerimo dimenzije plise zavesa?',
            message:
              'Za plise zavese mere su veoma jednostavne. Potrebno je samo da izmerite širinu i visinu stakla.',
          },
          {
            title: 'Da li je moguće montirati plise zavese na krovne prozore?',
            message:
              'Plise zavese su idealno rešenje za krovne prozore. Konačno ćete moci da spavate duže!',
          },
          {
            title:
              'Da li je moguće da plise zavese budu izrađene u nepravilnim oblicima?',
            message:
              'Nažalost, plise zavese nije moguće izraditi u nepravilnim oblicima.',
          },
          {
            title: 'Šta znači blackout platno?',
            message:
              'Blackout platno predstavlja materijal kroz koji ne prolazi svetlost i pravi potpuno zamračenje.',
          },
          {
            title: 'Koliki je rok isporuke?',
            message:
              'Rok isporuke za plise zavese je 2-4 nedelje od momenta kada se uplati avans.',
          },
        ]}
        faqImg={SlideFirstPhone}
      />
    </>
  );
};

export default Plise;
